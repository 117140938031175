import * as React from "react";
import { Routes, Route } from "react-router-dom";
// import Navbar from './Navbar';
import { HomePage, ResumePage, AboutPage, ManagementPage, ProjectsPage } from './routes';

function App() {
  return (
    <div className="App row site">
      <Routes>            
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="resume" element={<ResumePage />} />
          <Route path="management" element={<ManagementPage />} />
          <Route path="projects" element={<ProjectsPage />} />
      </Routes>
    </div>
  );
}

export default App;