import React from 'react';
import { Link } from 'react-router-dom';
import { default as Navbar } from '../Navbar';

function HomePage() {
  return (
    <div className="sm-12 md-12 col">

      <div className="paper">

        <div className='row flex-center'>
          <div className='text-center'>
            <h1>Welcome to ChadZink.com</h1>

            <Navbar selectedPath='/' />
            
            <div className="row flex-center">
              <img src="/images/cabin-porch.jpg" style={{ width: '30%', minWidth: 400 }} alt="home title" />
            </div>
            
            <h3>My personal website</h3>
          </div>
        </div>

        <div className="row flex-center" style={{ maxWidth: 1000, lineHeight: '1.25rem' }}>
          <p>
            I am thrilled to have you here. This platform serves as a window into my world as a passionate software engineering manager and developer. Whether you are an aspiring software professional, a potential employer, or simply curious about my journey, you'll find a wealth of information to explore.
          </p>
          <ul>
            <li>
              <Link to="resume">Your Gateway to My Online Resume</Link>:
              For employers seeking a reliable and dynamic addition to their teams, my website doubles as an interactive and comprehensive online resume. Get insights into my professional achievements, qualifications, and the unique contributions I can make to your organization.
            </li>
            <li>
              <Link to="management">Discover the Software Engineering Manager and Developer</Link>:
              Discover the Software Engineering Manager and Developer: Delve into the pages that reveal my experiences, skills, and expertise as a software engineering manager and developer. Here, I share my journey, the challenges I've overcome, and the valuable lessons I've learned along the way.
            </li>
            <li>
              <Link to="projects">Explore My Projects</Link>:
              Step back in time and explore some of my historical projects. From innovative applications to cutting-edge solutions, each project showcases my commitment to excellence and my love for tackling complex problems with creativity.
            </li>
          </ul>
          <p>
          As you navigate through these virtual pages, I hope you gain a deeper understanding of my passion for software engineering and the impact I strive to create in this ever-evolving field. Should you have any inquiries, collaborations, or exciting opportunities, feel free to reach out through the contact information provided.
          </p>
          <p>
            Thank you for visiting, and I look forward to connecting with you soon.
          </p>
        </div>

      </div>

    </div>
  );
}

export default HomePage;
