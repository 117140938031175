import React from 'react';
import { default as Navbar } from '../Navbar';

function AboutPage() {
  return (
    <div className="sm-12 md-12 col">

      <div className="paper">

        <div className='row flex-center'>
          <div className='text-center'>
            <h1>About Me</h1>
            
            <Navbar selectedPath='about' />
            
            <div className="row flex-center">
              <img src="/images/chad-profile.jpeg" style={{ width: '30%', minWidth: 100 }} alt="profile" />
            </div>

            <h3>Some history on my career</h3>
          </div>
        </div>

        <div className="row flex-center" style={{ maxWidth: 1000, lineHeight: '1.25rem' }}>
          <p>
            With over two decades of experience as a Software Developer, my journey began in 1999 at Pay Plus Benefits, a small yet dynamic company in Kennewick, Washington. Starting as a website developer, I steadily evolved into a web application developer, immersing myself in the Microsoft technology stack. The team at Pay Plus Benefits utilized Windows Server with IIS to deliver a remarkable SaaS application, powered by ASP.Net with C# as the robust backend, complemented by custom JavaScript, and later incorporating ExtJS for the frontend. Our database system of choice was MS-SQL. Over my 15-year tenure, I had the privilege of witnessing and contributing to the birth and growth of numerous web-based projects, honing my teamwork and project initiation skills along the way.
          </p>
          <p>
            In 2013, my thirst for exploration led me on an exciting transition, venturing into new realms of technology. I embarked on a diverse journey, taking me to various companies and roles. I worked briefly as a contractor at a nuclear power plant, gained invaluable experience at VtialWare—a web application company, and eventually found myself at WildLand Labs—a local software consulting firm. This phase proved to be transformative as I embraced a range of technologies, bidding farewell to my exclusive attachment to Microsoft. My knowledge expanded to encompass ColdFusion, Ruby on Rails, jQuery, EmberJS, React, and a diverse array of database systems such as MySQL, Oracle, and Postgresql. During this period, I discovered the joys of working within the open-source community, actively contributing and reaping the benefits of collective collaboration.
          </p>
          <p>
            My tenure at WildLand Labs also ignited a newfound passion for leadership and team management, setting me on the path to becoming a Software Engineering Manager. Starting with a gradual shift towards managing people, my responsibilities grew exponentially as I advanced to roles at Pacific Northwest National Laboratory (PNNL) and later at NextRoll. Today, I proudly devote the majority of my time to leading and guiding a team of skilled engineers, steering projects to success through effective management. The challenges inherent in this role invigorate me, and I am committed to continuously refining my leadership and management skills.
          </p>
          <p>
            Beyond my extensive work experience, I am a lifelong learner, and my educational background complements my professional growth. I hold a Bachelor's degree in Computer Science from Washington State University (WSU), complemented by an undergraduate degree in Business Administration from Columbia Basic College. Building on this foundation, I pursued a Master's in Business Administration from WSU, striking a harmonious balance between theoretical knowledge and practical application throughout my career journey.
          </p>
          <p>
            As I continue to forge ahead in the ever-evolving landscape of software development and management, I eagerly anticipate new challenges and opportunities to leverage my skills and expertise for the betterment of teams, projects, and organizations.
          </p>
          <p>
            Thank you for considering my profile, and I look forward to the possibility of contributing my passion and proficiency to your endeavors.
          </p>
        </div>

      </div>

    </div>
  );
}

export default AboutPage;
