import React from 'react';
import { Link } from 'react-router-dom';

type NavbarProps = {
    selectedPath: string,
}

const Navbar = (props: NavbarProps) => {
    const selectedPath = props.selectedPath;

    return (
        <div className="row flex-center child-borders">
            <Link to="/" className={`paper-btn margin ${selectedPath === null || selectedPath === '/' ? "disabled" : ""}`}>
                Home
            </Link>
            <Link to="/about" className={`paper-btn margin ${selectedPath === 'about' ? "disabled" : ""}`}>
                About Me
            </Link>
            <Link to="/resume" className={`paper-btn margin ${selectedPath === 'resume' ? "disabled" : ""}`}>
                My Resume
            </Link>
            <Link to="/management" className={`paper-btn margin ${selectedPath === 'management' ? "disabled" : ""}`}>
                Management
            </Link>
            {/* <Link to="/projects" className={`paper-btn margin ${selectedPath === 'projects' ? "disabled" : ""}`}>
                Projects
            </Link> */}
        </div>
    );
}

export default Navbar;