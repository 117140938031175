import React from 'react';
import { default as Navbar } from '../Navbar';

function ManagementPage() {
  return (
    <div className="sm-12 md-12 col">

      <div className="paper">

        <div className='row flex-center'>
          <div className='text-center'>
            <h1>Management</h1>
            
            <Navbar selectedPath='management' />
            
            <div className="row flex-center">
              <img src="/images/jason-goodman-bzqU01v-G54-unsplash.jpg" style={{ width: '30%', minWidth: 400 }} alt="profile" />
            </div>

            <h3>My Approach to Management</h3>
          </div>
        </div>

        <div className="row flex-center" style={{ maxWidth: 1000, lineHeight: '1.25rem' }}>

          <p>
            A Software Engineering Manager plays a crucial role in leading and guiding software development teams to success. I have organize some of my thoughts on the subject below.
          </p>

          {/* Emotional Intelligence */}

          <div className="card" style={{ width: '100%' }}>
            <div className="card-header">#1</div>
              <div className="card-body">
                <h4 className="card-title">Leadership</h4>
                <h5 className="card-subtitle">I believe in Servant Leadership</h5>
                <p className="card-text">
                  This is avery complex topic to summarize. Teams with good leadership are happier! Happy team result in more frequent success in solving problems and delivering software. As a manager, the ability to lead and inspire teams is paramount. This includes setting a clear vision, providing direction, and motivating team members to achieve their best performance.
                </p>
                <p className="card-text">
                  Some skills I have learned that I lean on are: Building roadmaps, Creating meaningful measurements, Facilitating meeting, Honesty & transparency, Leading by example.
                </p>
                <p className="card-text">
                  I try my best to practice Servant Leadership. This style aligns well with the collaborative, innovative, and dynamic nature of software engineering. By focusing on the well-being and growth of team members, while empowering them to excel, servant leaders can create high-performing teams capable of delivering exceptional software solutions.
                </p>
              </div>
          </div>
          
          <div className="card" style={{ width: '100%' }}>
            <div className="card-header">#2</div>
              <div className="card-body">
                <h4 className="card-title">Decision-Making</h4>
                <h5 className="card-subtitle">Learn from mistakes & repeat successes</h5>
                <p className="card-text">
                  A Software Engineering Manager needs to make sound decisions based on data and input from the team. The ability to weigh pros and cons, especially in challenging situations, is vital.
                </p>
                <p className="card-text">
                  I tend to lean on Collaborative Decision Making. This style works well fo rme, but is not my only approach. I have found that this style works well with teams that are not afraid to speak up and share their thoughts. I have also found that this style works well with teams that are not afraid to make mistakes. I have found that teams that are afraid to make mistakes tend to be more successful with a more directive style of decision making.
                </p>
                <p className="card-text">
                  I find it important to adjust the decision making process to the situation. Rational Decision Making is great when I have time to dig into a problem and really look for the best approach. However, I will also go to Decisive Decision Making when I need to act quickly and the situation allows for potential mistakes and learning fast is more important.
                </p>
              </div>
          </div>

          <div className="card" style={{ width: '100%' }}>
            <div className="card-header">#3</div>
              <div className="card-body">
                <h4 className="card-title">Adaptability</h4>
                <h5 className="card-subtitle">Be ready for change</h5>
                <p className="card-text">
                The tech industry evolves rapidly, so adaptability is crucial to stay current with new technologies and methodologies, and to navigate changes in project requirements. I lean on an Agile appraoch to managing because it allows for adaptability and change thru continious process improvement.
                </p>
              </div>
          </div>

          <div className="card" style={{ width: '100%' }}>
            <div className="card-header">#4</div>
              <div className="card-body">
                <h4 className="card-title">Emotional Intelligence</h4>
                <h5 className="card-subtitle">I try to know who I am</h5>
                <p className="card-text">
                Emotional Intelligence (EI) is a vital skill for Software Engineering Managers, as it empowers them to navigate the complex interpersonal dynamics within their teams with empathy and understanding. With EI, managers can effectively recognize and manage their emotions and those of their team members, fostering a positive and harmonious work environment. By empathizing with their team's challenges and concerns, Software Engineering Managers can provide support and guidance tailored to individual needs, boosting motivation and productivity. Additionally, EI enables managers to handle conflicts constructively, promoting open communication and collaboration. Emotionally intelligent leaders are better equipped to inspire trust and loyalty, nurturing a cohesive team that collaborates seamlessly and delivers exceptional results in the fast-paced and demanding world of software engineering.
                </p>
              </div>
          </div>

          <div className="card" style={{ width: '100%' }}>
            <div className="card-header">#5</div>
              <div className="card-body">
                <h4 className="card-title">Team Building</h4>
                <h5 className="card-subtitle">Assist memebrs find thier place on the team</h5>
                <p className="card-text">
                  Building and fostering a cohesive, collaborative, and motivated team is crucial for success. A manager should recognize individual strengths, provide opportunities for growth, and create a positive work environment. I have spent a lot of time with team members and workign with them to find their place on the team. I have found that when team members are in the right role, they are happier and more productive. I have also found that when team members are in the right role, the team is more productive.
                </p>
              </div>
          </div>

          <div className="card" style={{ width: '100%' }}>
            <div className="card-header">#6</div>
              <div className="card-body">
                <h4 className="card-title">Technical Proficiency</h4>
                <h5 className="card-subtitle">I still like being involved in the technical things</h5>
                <p className="card-text">
                  While not necessarily involved in hands-on coding, a Software Engineering Manager should have a strong technical background to understand the challenges faced by the team and offer informed guidance and support. I try to stay up to speed on this by 1) staying active in the code reviews and providing meaningful feedback, 2) reading tech articles and staying up to speed on trends in the industry, 3) having my own side projects that I can expierement with new technologies.
                </p>
              </div>
          </div>

          <div className="card" style={{ width: '100%' }}>
            <div className="card-header">#7</div>
              <div className="card-body">
                <h4 className="card-title">Operational Tasks</h4>
                <h5 className="card-subtitle">Somebody needs to keep things tidy</h5>
                <p className="card-text">
                  It is important for a manager not to lose focus on the operational tasks that the organization needs done to keep things running smoothly. Ideally you can keep the business operatiosn running and unburden your team from having to take care of these tasks themselfs. Let them focus on the software and you focus on the business.
                </p>
                <p className="card-text">
                  Examples of some of the operational tasks a manager can expect to handle for their team include: Budgeting, Hiring, Performance Reviews, Project Planning, Leave Approval, Resource Allocation, and Time Management.
                </p>
              </div>
          </div>

        </div>

      </div>

    </div>
  );
}

export default ManagementPage;
