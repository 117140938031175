import React from 'react';
import { default as Navbar } from '../Navbar';

function ProjectsPage() {
  return (
    <div className="sm-12 md-12 col">

      <div className="paper">

        <div className='row flex-center'>
          <div className='text-center'>
            <h1>Projects</h1>
            
            <Navbar selectedPath='projects' />
            
            <div className="row flex-center">
              <img src="/images/glass-on-beach.jpg" style={{ width: '30%', minWidth: 400 }} alt="profile" />
            </div>

            <h3>Projects & Role</h3>
          </div>
        </div>

        <div className="row flex-center" style={{ maxWidth: 1000, lineHeight: '1.25rem' }}>
          <p>
            Work In Progress
          </p>
        </div>

      </div>

    </div>
  );
}

export default ProjectsPage;
