import React from 'react';
import { default as Navbar } from '../Navbar';

function ResumePage() {
  return (
    <div className="sm-12 md-12 col">

      <div className="paper">

        <div className='row flex-center'>
          <div className='text-center'>
            <h1>My Resume</h1>
            
            <Navbar selectedPath='resume' />
          </div>
        </div>

        <div className="row flex-center" style={{ maxWidth: 1400, lineHeight: '1.25rem' }}>
          
          <div className='text-left sm-12 md-4 col'>
            <div className="card" style={{width: '20rem'}}>
              <img src="/images/skill_card.jpg" style={{ padding: 10 }} alt="skills-card" />
              <div className="card-body">
                <h4 className="card-title">SKILLS</h4>
                <p className="card-subtitle">Over the years I have developed a few skills in different areas. I try to learn something new as often as I can; however, these are the skills I have developed thru applying them to projects.</p>
                <ol>
                  <li>
                    <b>Leadership</b>:
                    Measurements (OKR & KIP), Roadmaps, Meeting Facilitation, Coaching, Hiring, and Staff Evaluations.
                    <br /><br />
                  </li>
                  <li>
                    <b>Project Management</b>:
                    Agile, Waterfall, ScrumMaster Certification, Jira, Asana, GitHub Projects, Redmine, Github, GilLab, Jixee, and Axosoft.
                    <br /><br />
                  </li>
                  <li>
                    <b>Database Design/Dev</b>:
                    SQL, ETL, MS-SQL Server, Azure SQL, PostgreSQL, MySQL.
                    <br /><br />
                  </li>
                  <li>
                    <b>Web Frontend</b>:
                    JavaScript, HTML, CSS, ReactJS, Svelte, EmberJS, ExtJS, jQuery
                    <br /><br />
                  </li>
                  <li>
                    <b>Web Backend</b>:
                    ASP.Net (with C#, VB), Go, Ruby on Rails, PHP Symfony, Python Flask, ColdFusion
                    <br /><br />
                  </li>
                  <li>
                    <b>Desktop Applications</b>:
                    Windows Forms (C#, VB), CLI (C#, PowerShell, C++, & Python), Electron App, WAILS
                    <br /><br />
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className='text-left sm-12 md-4 col'>
            <div className="card" style={{width: '20rem'}}>
              <img src="/images/job_card.png" style={{ padding: 10 }} alt="job-card" />
              <div className="card-body">
                <h4 className="card-title">JOBS</h4>
                <p className="card-subtitle">Over the years I have worked at a few different companies. I have worked in a few different roles and enjoyed each, but I have always been an engineer at heart.</p>

                <ol>
                <li>
                    Magenta Technology<br />
                    Software Engineering Manager<br />
                    Ft. Mill, SC (Remote)<br />
                    09/2023 - Current<br />
                    <br />
                    <span style={{ fontSize: '0.9rem' }}>
                      At M-Tech, I hold the position of Software Engineering Manager overseeing the Backend Chapter. Within this capacity, I lead a dynamic team structure comprising three distinct teams. In my role, I am responsible for the management of seven direct reports distributed among these teams. Our operational approach is characterized by a flexible work methodology that readily adjusts to the evolving requirements of our projects.<br />
                    </span>
                    <br />
                  </li>
                  <li>
                    AdRoll (division of NextRoll)<br />
                    Software Engineering Manager<br />
                    San Francisco, CA (Remote)<br />
                    03/2022 - 07/2023<br />
                    <br />
                    <span style={{ fontSize: '0.9rem' }}>
                      In my capacity at NextRoll, I am entrusted with the management and leadership of software engineering teams tasked with the execution of our product plan. In addition to overseeing team performance, I actively participate in the product development planning process, with a particular emphasis on analyzing scope and scheduling aspects to ensure alignment with strategic objectives.<br />
                    </span>
                    <br />
                  </li>
                  <li>
                    Pacific Northwest National Laboratory<br />
                    Software Engineer & Team Lead<br />
                    Richland, WA<br />
                    01/2019 - 03/2022<br />
                    <br />
                    <span style={{ fontSize: '0.9rem' }}>
                      My work at PNNL as a software engineer is focused on delivering web applications to sponsors with a focus on data visualization for end user decision making. I also lead a team of up to 12 software engineers, system engineers, and project managers. This includes interns, entry level, and senior staff. In this position I mostly do task management as a technical lead on small to large scale software applications.<br />
                    </span>
                    <br />
                  </li>
                  <li>
                    Wildland Labs (now BrandCraft)<br />
                    Software Development Manager<br />
                    Richland, WA<br />
                    11/2015 - 01/2019<br />
                    <br />
                    <span style={{ fontSize: '0.9rem' }}>
                      Lead the team of 7 software developers to follow the Wildland software development life cycle (SDLC). Identify adjustments to the SDLC and make changes as needed to improve team effectiveness. Participate in the process of application application projects. This includes working in the Wildland core technology stack (Ruby on Rails, EmberJS, Heroku, Postgresql) as well as working with legacy application projects in Microsoft technologies (C#, VB.Net, and VB 6).<br />
                    </span>
                    <br />
                  </li>
                  <li>
                    VitalWare<br />
                    Senior Web Developer & Team Lead<br />
                    Yakima, WA<br />
                    10/2014 - 11/2015<br />
                    <br />
                    <span style={{ fontSize: '0.9rem' }}>
                      Lead small team of 3 developer on the design and development of VitalWare web application for medical coding and billing. VitalWare technology stack was ColdFusion, Ext JS, MS-SQL Server with browser support for Chrome, Firefox, and IE 8+.<br />
                    </span>
                    <br />
                  </li>
                  <li>
                    Mid-Columbia Engineering<br />
                    Contract Software Engineer<br />
                    Richland, WA<br />
                    06/2013 - 01/2014<br />
                    <br />
                    <span style={{ fontSize: '0.9rem' }}>
                      Managed the software development life-cycle for legacy application conversions for Energy Northwest. This included planning and documenting the process of gathering requirements, designing the application, and implementation.<br />
                    </span>
                    <br />
                  </li>
                  <li>
                    Pay Plus Benefits<br />
                    Senior Software Engineer<br />
                    Kennewick, WA<br />
                    01/1999 - 10/2014<br />
                    <br />
                    <span style={{ fontSize: '0.9rem' }}>
                      Participated in and led teams in the process of improving business software systems using a process of innovation and design for rapid application development. This included web applications and software applications that extended or enhanced payroll, HR, and accounting systems.<br />
                    </span>
                    <br />
                  </li>
                </ol>
                
              </div>
            </div>
          </div>

          <div className='text-left sm-12 md-4 col'>
            <div className="card" style={{width: '20rem'}}>
              <img src="/images/education_card.jpg" style={{ padding: 10 }} alt="job-card" />
              <div className="card-body">
                <h4 className="card-title">EDUCATION</h4>

                <ol>
                 <li>
                    Certified ScrumMaster® (CSM®)<br />
                    Scrum Alliance<br />
                    Completed: 2023<br />
                    <br />
                  </li>
                  <li>
                    Masters of Business Administration<br />
                    Washington State University<br />
                    Completed: 2014<br />
                    Richland, WA<br />
                    <br />
                  </li>
                  <li>
                    Bachelor of Applied Science, Applied Management<br />
                    Columbia Basin College<br />
                    Completed: 2012<br />
                    Pasco, WA<br />
                    <br />
                  </li>
                  <li>
                    Bachelor of Science, Computer Sciences<br />
                    Washington State University<br />
                    Completed: 2004<br />
                    Richland, WA<br />
                    <br />
                  </li>
                </ol>

                <p>I started my career as a software engineer and then spent time learning business management. These degrees have paired well with developing software teams that deliver solutions to end users. However, my commitment to continued learning extends beyond formal education, as I actively seek out new knowledge and stay abreast of the latest advancements in both technology and business practices. This dedication to lifelong learning enables me to adapt to ever-changing industry landscapes and consistently provide innovative and effective solutions to meet the needs of my team and clients.</p>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default ResumePage;
